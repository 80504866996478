import teamMembers from "@/services/teamMembers";
import {
  getFilterQueryStringWithoutArray,
  isObject,
  isEmptyObjectCheck,
  getCustomerId,
  getOrgId,
} from "@/helpers/helper.js";
import router from "../router/index";
import getUserId from "../modules/auth";

const getDefaultState = () => {
  return {
    teamList: [],
    teamLevelList: [],
    recruitmentTypesList: [],
    teamMembersList: [],
  };
};

const state = getDefaultState();

const getters = {
  teamList: (state) => state.teamList,
  getTeamList: (state) =>
    state.teamList.length
      ? state.teamList.map((val) => ({
          code: val.id,
          label: val?.team_name,
        }))
      : [],
  getTeamLevel: (state) =>
    state.teamLevelList.length
      ? state.teamLevelList.map((val) => ({
          code: val.id,
          label: val?.level_name,
        }))
      : [],
  getRecruitmentTypes: (state) =>
    state.recruitmentTypesList.length
      ? state.recruitmentTypesList.map((val) => ({
          code: val.id,
          label: val?.type_name,
        }))
      : [],
  getAllTeamMembers: (state) => state.teamMembersList,
};

const mutations = {
  ["SET_TEAM_DETAIL"](state, payload) {
    state.teamList = payload;
  },
  ["SET_TEAM_LEVELS"](state, payload) {
    state.teamLevelList = payload;
  },
  ["SET_RECRUITMENT_TYPES"](state, payload) {
    state.recruitmentTypesList = payload;
  },
  ["SET_TEAM_MEMBERS"](state, payload) {
    state.teamMembersList = payload;
  },
};
const actions = {
  getTeamDetails: ({ getters, commit }, payload) => {
    let { customer_id, organisation_id } = payload;
    return teamMembers
      .getTeams(customer_id, organisation_id)
      .then((res) => {
        const { data } = res;
        commit("SET_TEAM_DETAIL", data);
        return res;
      })
      .catch((err) => {
        console.log("Error while Fetch Team Details", err);
        return err;
      });
  },
  getTeamLevels: ({ getters, commit }) => {
    return teamMembers
      .getTeamLevel()
      .then((res) => {
        const { data } = res;
        commit("SET_TEAM_LEVELS", data);
        return res;
      })
      .catch((err) => {
        console.log("Error while Fetch Team levels", err);
        return err;
      });
  },
  getRecruitmentType: ({ getters, commit }) => {
    return teamMembers
      .getRecruitmentTypes()
      .then((res) => {
        const { data } = res;
        commit("SET_RECRUITMENT_TYPES", data);
        return res;
      })
      .catch((err) => {
        console.log("Error while Fetch Recruitment Types", err);
        return err;
      });
  },
  getTeamMembers: ({ getters, commit }, payload) => {
    let { customer_id, organisation_id } = payload;
    return teamMembers
      .getAllTeamMembers(customer_id, organisation_id)
      .then((res) => {
        const { data } = res;
        commit("SET_TEAM_MEMBERS", data);
        return res;
      })
      .catch((err) => {
        console.log("Error while Fetching Team Members", err);
        return err;
      });
  },
  createNewTeamMember: ({ dispatch, getters }, payload) => {
    const {  getCustomerId: customer_id, getOrgID: organisation_id } = getters;
    let { team_id, team_level_id, recruitment_type_id, ...rest } = payload;
    dispatch("showToast", { message: "Creating new Team Member..." });
    return teamMembers
      .createNewTeamMembers({
        customer_id,
        organisation_id,
        team_id,
        team_level_id,
        recruitment_type_id,
        payload: rest,
      })
      .then((res) => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Recruiter Added successfully",
        });
        router.push("/list-recruiter");
        return res;
      })
      .catch((err) => {
        console.log("Error while creating new Team Members ", err);
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: err.response.data.detail,
        });
        return err;
      });
  },
  updateTeamMember: ({ dispatch, getters }, payload) => {
    const { getUserId } = getters;
    let { updated_by } = payload;
    console.log("module ", payload);
    dispatch("showToast", {
      class: "bg-success text-white",
      message: "Updating...",
    });
    return teamMembers
      .updateTeamMembers({ updated_by: getUserId, payload })
      .then((res) => {
        dispatch("clearAllToast");
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Updated successfully",
        });
        return res;
      })
      .catch((err) => {
        let msg = "Error while Updating TeamMembers";
        console.log("error while Updating Team Members", err);
        dispatch("clearAllToast");
        dispatch("showToast", { class: "bg-danger text-white", message: msg });
        return err;
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
