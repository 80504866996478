import managementReport from "@/services/managementReport";
import tabFilters from "@/services/tabFilter";
import { getFilterQueryStringWithoutArray, getCurrentTimeStamp } from "@/helpers/helper";
const getDefaultState = () => {
    return {
        selectedColumn: {},
        managementReportCount: [],
        userConfiguration: []
    };
};

const state = getDefaultState();

const getters = {
    getSelectedColumn: (state) => state.selectedColumn,
    getManagementReportCount: state => state.managementReportCount,
    getUserConfiguration: (state) => state.userConfiguration
};

const mutations = {
    ["SET_SELECTED_COLUMN"](state, data) {
        state.selectedColumn = data;
    },
    ["RESET_SELECTED_COLUMN"](state) {
        state.selectedColumn = {};
    },
    ["SET_MANAGEMENT_REPORT_COUNT"](state, data) {
        state.managementReportCount = data;
    },
    ["RESET_MANAGEMENT_REPORT_COUNT"](state) {
        state.managementReportCount = [];
    },
    ["SET_USER_CONFIGURATION"](state, payload) {
        state.userConfiguration = payload;
    }
};

const actions = {
    fetchJobCandidateProcessCount({ commit, getters, dispatch }, payload = {}) {
        const { getCustomerId: customer_uid, getOrgIDFromAccessToken: organisation_id, getIsParentUserFromAccessToken, isUKMainAgency } = getters
        let queryPayload = {
            all_rows: true,
            order_by: "-job_id",
            fetch_row_count: false,
            ...payload
        };
        if (customer_uid)
            queryPayload = { ...queryPayload, customer_uid };
        if (organisation_id && !getIsParentUserFromAccessToken && !isUKMainAgency)
            queryPayload = { ...queryPayload, organisation_uid: organisation_id };
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        commit("RESET_MANAGEMENT_REPORT_COUNT");
        commit("RESET_SELECTED_COLUMN");
        dispatch("showLoader")
        return managementReport.
            getJobCandidateProcessCount(queryString)
            .then(res => {
                const { data } = res;
                commit("SET_MANAGEMENT_REPORT_COUNT", data);
                return res;
            })
            .catch(err => {
                return err;
            })
            .finally(() => {
                dispatch("hideLoader")
            })
    },
    setSelectedColumn({ commit }, payload) {
        commit("SET_SELECTED_COLUMN", payload);
        return;
    },
    initFetchJobCandidateReport({ commit, getters, dispatch }, data) {
        const { isJobSupplierFromAccessToken, isCandidateSupplierFromAccessToken } = getters;
        const { tab_id, filter_id, payload, job_id, skip, limit } = data;
        let queryPayload = { ...payload };
        if (tab_id)
            queryPayload = { ...queryPayload, tab_id }
        if (filter_id)
            queryPayload = { ...queryPayload, filter_id__in: filter_id }
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        return tabFilters
            .getDisplayStatusSubStatus({ queryString })
            .then(async (res) => {
                const { data } = res;
                let displayStatusSubStatus = data
                if (isJobSupplierFromAccessToken)
                    displayStatusSubStatus = _(data).filter(({ display_status }) => display_status != 75).value()
                if (isCandidateSupplierFromAccessToken)
                    displayStatusSubStatus = _(data).filter(({ display_status }) => display_status != 67).value()
                commit("SET_FILTER_DISPLAY_STATUS_SUB_STATUS", displayStatusSubStatus);
                await dispatch("fetchJobCandidatesReport", { displayStatusSubStatus, payload, skip, limit, tab_id, filter_id, })
                return res;
            })
            .catch((err) => {
                const message = 'Error While Fetching Status';
                console.log(message, err);
                dispatch("showToast", {
                    class: 'bg-danger text-white',
                    message
                })
                return err;
            })
    },
    fetchJobCandidatesReport({ commit, dispatch, getters }, payload) {
        const {
            displayStatusSubStatus,
            getJobCandidateStatusPagination,
            getJobCandidatesByStatusID,
            getChildOrgWithCustomerQuery,
            getJobCandidatesByStatusIDCount,
            getOrgTypeIDFromAccessToken,
            childHasNoAcronym,
            isHeadlessClient,
        } = getters;
        const { payload: queryPayload } = payload
        if (!displayStatusSubStatus?.length) {
            dispatch("resetJobCandidateStatus");
            commit("SET_FETCHING_JOB_CANDIDATE_STATUS", false);
            commit("SET_JOB_CANDIDATE_STATUS_PAGINATION", {
                noMoreCandidates: true
            });
            return;
        }
        commit("SET_FETCHING_JOB_CANDIDATE_STATUS", true);
        if (!payload.pagination && (payload?.skip ? !getJobCandidatesByStatusIDCount : true)) {
            commit("SET_JOB_CANDIDATE_STATUS_PAGINATION", {
                skip: 0,
                noMoreCandidates: false,
            });
            dispatch("fetchJobCandidatesCountReport", payload)
        }
        let skip = payload.pagination ? getJobCandidateStatusPagination.skip : 0;
        let limit = getJobCandidateStatusPagination.limit;

        const { skip: paginationSkip, limit: paginationLimit } = payload;
        if (paginationLimit) {
            skip = paginationSkip || 0;
            limit = paginationLimit;
        }
        let queryString = getFilterQueryStringWithoutArray(queryPayload);
        queryString += `${queryString ? '&' : ''}${getChildOrgWithCustomerQuery}`;
        return tabFilters
            .fetchJobCandidatesByStatusID({
                skip: skip,
                limit: limit,
                queryString: queryString,
                formPayload: displayStatusSubStatus,
            })
            .then((res) => {
                const { data } = res;
                if (data.length < limit) {
                    commit("SET_JOB_CANDIDATE_STATUS_PAGINATION", {
                        noMoreCandidates: true
                    });
                } else {
                    commit("SET_JOB_CANDIDATE_STATUS_PAGINATION", {
                        skip: skip + limit,
                        noMoreCandidates: false
                    });
                }
                commit("SET_JOB_CANDIDATES_BY_STATUS_ID", data);
                return res;
            })
            .catch((err) => {
                const message = 'Error While Fetching Candidates';
                console.log(message, err);
                dispatch("showToast", {
                    class: 'bg-danger text-white',
                    message
                })
                return err;
            })
            .finally((res) => {
                commit("SET_FETCHING_JOB_CANDIDATE_STATUS", false);
                return res;
            });
    },
    fetchJobCandidatesCountReport({ commit, getters, dispatch }, data) {
        const { getChildOrgWithCustomerQuery } = getters;
        const { tab_id, filter_id, payload } = data
        let queryPayload = { ...payload };
        if (tab_id)
            queryPayload = { ...queryPayload, tab_id };
        if (filter_id)
            queryPayload = { ...queryPayload, filter_id__in: filter_id }
        let queryString = getFilterQueryStringWithoutArray(queryPayload)
        queryString += `${queryString ? '&' : ''}${getChildOrgWithCustomerQuery}`;
        return tabFilters
            .tabFilterCandidateCount({ queryString }).then(res => {
                const { data } = res
                commit("SET_JOB_CANDIDATES_BY_STATUS_ID_COUNT", data);
                return res;
            }).catch((err) => {
                const message = 'Error While Fetching Candidates Count';
                console.log(message, err);
                dispatch("showToast", {
                    class: 'bg-danger text-white',
                    message
                })
                return err;
            })
    },
    downloadManagementReport({ commit, dispatch, getters }, payload = {}) {
        dispatch("showFixedToast",
            { class: 'bg-secondary text-white', message: 'Preparing to Download...' })
        const { getCustomerId: customer_uid, getOrgIDFromAccessToken: organisation_id, getIsParentUserFromAccessToken, isUKMainAgency } = getters;
        const queryPayload = {
            customer_uid,
            ...payload,
            order_by: "-job_id",
        }

        if (organisation_id && !getIsParentUserFromAccessToken && !isUKMainAgency)
            queryPayload.organisation_uid = organisation_id;
        const queryString = getFilterQueryStringWithoutArray(queryPayload)
        return managementReport
            .downloadManagementReport(queryString)
            .then(res => {
                let { data } = res;
                if (!data) {
                    dispatch("showToast",
                        { class: 'bg-success text-white', message: 'No data to download' })
                    return
                }
                const document_name = `management_report_${getCurrentTimeStamp}`
                const link = document.createElement("a");
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(data);
                    link.setAttribute("href", url);
                    link.setAttribute("download", document_name);
                    link.style.visibility = "hidden";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                return res;
            })
            .catch(err => {
                dispatch("showToast",
                    { class: 'bg-danger text-white', message: 'Download failed. Try again.' })
                console.error("error in Download Document", err);
                return err;
            })
            .finally(() => {

                dispatch("hideFixedToast")
            })
    },
    fetchUserConfiguration({ commit, getters }) {
        let { getUserId, getCustomerId, getOrgIDFromAccessToken } = getters
        let payload = {
            user_id: getUserId,
            customer_uid: getCustomerId,
            organisation_id: getOrgIDFromAccessToken
        }
        let queryString = getFilterQueryStringWithoutArray(payload)
        return managementReport
            .fetchUserConfiguration(queryString).then(res => {
                const { data } = res
                commit("SET_USER_CONFIGURATION", data[0]);
                return res;
            }).catch((err) => {
                console.log("error while fetching user configuration", err);
                return err;
            })
    },
    createUserConfiguration({ commit, getters, dispatch }, payload) {
        let { getUserId, getCustomerId, getOrgID } = getters
        let finalPayload = {
            user_id: getUserId,
            customer_uid: getCustomerId,
            organisation_id: getOrgID,
            allowed_fields: payload
        }
        return managementReport
            .createUserConfiguration(finalPayload).then(res => {
                const { data } = res
                commit("SET_USER_CONFIGURATION", data);
                dispatch("showToast",
                    { class: 'bg-success text-white', message: 'Configuration Updated' })
                return res;
            }).catch((err) => {
                console.log("error while creating user configuration", err);
                dispatch("showToast",
                    { class: 'bg-danger text-white', message: 'Error while updating' })
                return err;
            })
    },
    updateUserConfiguration({ commit, dispatch }, payload) {
        return managementReport
            .updateUserConfiguration(payload.configuration_id, payload).then(res => {
                const { data } = res
                commit("SET_USER_CONFIGURATION", data);
                dispatch("showToast",
                    { class: 'bg-success text-white', message: 'Configuration Updated' })
                return res;
            }).catch((err) => {
                console.log("error while updating user configuration", err);
                dispatch("showToast",
                    { class: 'bg-danger text-white', message: 'Error while updating' })
                return err;
            })
    },
};

export default { state, getters, mutations, actions };