import organisationDocuments from "@/services/organisationDocuments";
import { getFilterQueryStringWithoutArray } from "@/helpers/helper.js";
const getDefaultState = () => {
  return {
    allDocumentByOrganisationId: [],
    getOrganisationDocumentFile: "",
    lastFetchedDocumentByOrganisationID: "",
    getOrgLocation: [],
    getOrgDocumentTypes: [],
    getOrgDocumentTitles: [],
    getOrgDocumentLibrary: [],
    getOrgDocumentTitlesAll: [],
    documentDeleted: false,
  };
};

const state = getDefaultState();

const getters = {
  getAllDocumentByOrganisationId: (state) => state.allDocumentByOrganisationId,
  getAllOrganisationDocumentByType: (state) => (document_type_id) => {
    return state.allDocumentByOrganisationId.filter(
      (document) => document.document_type_id == document_type_id
    ).sort((a) => a.default_document ? -1 : 1);
  },
  getOrgLocation: (state) => state.getOrgLocation,
  getOrgDocumentTypes: (state) => state.getOrgDocumentTypes && state.getOrgDocumentTypes.length
    ? state.getOrgDocumentTypes.map(val => ({
      label: val.document_type,
      code: val.document_type_id,
      allowed_extensions: val.allowed_extensions
    }))
    : [],
  getOrgDocumentTitles: (state) => state.getOrgDocumentTitles && state.getOrgDocumentTitles.length
    ? state.getOrgDocumentTitles.map(val => ({
      label: val.document_name,
      code: val.documenttitle_id,
      allowed_extensions: val.allowed_extensions
    }))
    : [],
};

const mutations = {
  ["SET_ALL_DOCUMENT_BY_ORGANISATION_ID"](state, payload) {
    state.allDocumentByOrganisationId = payload;
  },
  ["LAST_FETCHED_DOCUMENT_BY_ORGANISATION_ID"](state, payload) {
    state.lastFetchedDocumentByOrganisationID = payload;
  },
  ["SET_ORGANISATION_DOCUMENT_FILE"](state, payload) {
    state.getOrganisationDocumentFile = payload;
  },
  ["SET_ORG_LOCATION"](state, payload) {
    state.getOrgLocation = payload;
  },
  ["SET_ORG_DOCUMENT_TYPE"](state, payload) {
    state.getOrgDocumentTypes = payload;
  },
  ["SET_ORG_DOCUMENT_TITLE"](state, payload) {
    state.getOrgDocumentTitles = payload;
  },
  ["SET_ORG_DOCUMENT_TITLE_ALL"](state, payload) {
    state.getOrgDocumentTitlesAll = payload;
  },
  ["SET_ORG_DOCUMENT_LIBRARY"](state, payload) {
    state.getOrgDocumentLibrary = payload;
  },
  ["SET_DOCUMENT_DELETED"](state, payload) {
    state.documentDeleted = payload;
    setTimeout(() => {
      state.documentDeleted = false;
    }, 500);
  },
};

const actions = {
  fetchAllVersionDocumentByOrgId: (
    { state, commit, rootState, dispatch, getters },
    payload
  ) => {
    const { getOrgIDFromAccessToken: org_id } = getters
    let { organisation_id, skipLastFetch } = payload;
    if (!organisation_id) organisation_id = org_id ? org_id : null
    if (organisation_id == state.lastFetchedDocumentByOrganisationID && !skipLastFetch) return;
    commit("LAST_FETCHED_DOCUMENT_BY_ORGANISATION_ID", organisation_id)
    commit("SET_ALL_DOCUMENT_BY_ORGANISATION_ID", []);
    let query = `only_latest_versions=false`
    return organisationDocuments
      .getDocument(organisation_id, query)
      .then(async (res) => {
        commit("SET_ALL_DOCUMENT_BY_ORGANISATION_ID", res.data);
      })
      .catch((err) => {
        console.log("error fetching organisation document by org_id", err);
        return err;
      });
  },
  fetchOrgDocumentType({ commit, getters }) {
    return organisationDocuments
      .getOrgDocumentType()
      .then(async res => {
        commit("SET_ORG_DOCUMENT_TYPE", res.data);
      })
      .catch(err => {
        console.log("error fetch document type", err);
        return err;
      });
  },
  createDocumentForOrganisation: ({ state, commit, dispatch }, payload) => {
    let { organisation_id, document_type_id, ...rest } = payload
    dispatch("showToast", { message: 'Uploading...' })
    const file = new FormData();
    if (rest.file && rest.fileName) file.append("file", rest.file, rest.fileName);
    file.append("document_type_id", document_type_id);
    if (payload?.comments) file.append("comments", payload.comments)
    return organisationDocuments
      .createDocument(organisation_id, file)
      .then(res => {
        dispatch("fetchAllVersionDocumentByOrgId", { organisation_id, skipLastFetch: true })
        commit("SET_UPLOADED", true);
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "File Uploaded Successfully",
        })
        return res;
      }).catch(err => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error While Uploading",
        });
        console.log("Error while creating document", err);
        return err;
      })
  },
  updateOrganisationDocument: ({ state, commit, dispatch }, payload) => {
    let { organisation_id, document_type_id, organisation_document_id, ...rest } = payload
    dispatch("showToast", { message: 'Uploading...' })
    const file = new FormData();
    if (rest.file && rest.fileName) file.append("file", rest.file, rest.fileName);
    file.append("document_type_id", document_type_id);
    if (payload?.comments) file.append("comments", payload.comments)
    return organisationDocuments
      .updateOrganisationDocument(organisation_id, organisation_document_id, file)
      .then(res => {
        dispatch("fetchAllVersionDocumentByOrgId", { organisation_id, skipLastFetch: true })
        commit("SET_UPLOADED", true);
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "File Updated Successfully",
        })
        return res;
      }).catch(err => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error While Updating",
        });
        console.log("Error while creating document", err);
        return err;
      })
  },
  makeOrgDocumentDefault: ({ state, commit, dispatch }, payload) => {
    let { organisation_id, organisation_document_id } = payload
    dispatch("showToast", { message: 'Uploading...' })
    return organisationDocuments
      .makeOrgDocumentDefault(organisation_id, organisation_document_id)
      .then(res => {
        dispatch("fetchAllVersionDocumentByOrgId", { organisation_id, skipLastFetch: true })
        commit("SET_UPLOADED", true);
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Updated Successfully",
        })
        return res;
      }).catch(err => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error While Updating",
        });
        console.log("Error while updating document", err);
        return err;
      })
  },
  updateDocumentLibrary: ({ state, commit, dispatch }, payload) => {
    const { document_library_id } = payload
    return organisationDocuments
      .updateDocumentLibrary(document_library_id, payload)
      .then(res => {
        commit("SET_UPLOADED", true);
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Updated Successfully",
        })
        return res;
      }).catch(err => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error In Updating Documents Library",
        });
        console.log("Error In Updating Documents Library", err);
        return err;
      })
  },
  deleteOrganisationDocumentFile: ({ state, commit, dispatch, getters }, payload) => {
    const { organisation_id, organisation_document_id } = payload;
    return organisationDocuments
      .deleteDocumentFile(organisation_id, organisation_document_id)
      .then(res => {
        dispatch("fetchAllVersionDocumentByOrgId", { organisation_id: organisation_id, skipLastFetch: true })
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "File Deleted Successfully",
        })
        const { data } = res
        if (data.deleted) {
          commit("SET_DOCUMENT_DELETED", true)
        } else {
          commit("SET_DOCUMENT_DELETED", false)
        }
        return res;
      }).catch(err => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error while deleting",
        })
        console.log("Error while deleting File", err);
        return err;
      })
  },
  deleteDocumentForOrganisation: ({ state, commit, dispatch }, payload) => {
    return organisationDocuments
      .deleteDocument(payload)
      .then(res => {
        dispatch("fetchAllVersionDocumentByOrgId", { organisation_id: payload.organisation_id, skipLastFetch: true })
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Document Deleted Successfully",
        })
        return res;
      }).catch(err => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error while deleting",
        })
        console.log("Error while deleting document", err);
        return err;
      })
  },
  fetchOrgLocation: ({ state, commit, getters }) => {
    const { location_id } = getters.getUser.org_detail
    return organisationDocuments
      .getOrgLocation(location_id)
      .then(res => {
        const { data } = res;
        commit("SET_ORG_LOCATION", data)
        return data;
      })
      .catch(err => {
        console.log("Error organization location", err);
        return err;
      });
  },
  //getDocumentLibraryByOrgId:
  fetchDocumentLibraryByOrgId: ({ state, commit, dispatch }, organisation_id) => {
    return organisationDocuments
      .getDocumentLibraryByOrgId(organisation_id)
      .then(async res => {
        commit("SET_ORG_DOCUMENT_LIBRARY", res.data);
      })
      .catch(err => {
        console.log("error fetch document library", err);
        return err;
      })
  },
  createDocumentLibrary: ({ state, commit, dispatch }, payload) => {
    return organisationDocuments
      .createDocumentLibrary(payload)
      .then(res => {
        commit("SET_UPLOADED", true);
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Inserted Successfully",
        })
        return res;
      }).catch(err => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error In insertion Documents Library",
        });
        console.log("Error In insertion Documents Library", err);
        return err;
      })
  },
};

export default { state, getters, mutations, actions };