import orgConfiguration from "@/services/orgConfiguration";
import dynamicOrgUrl from "@/services/dynamicOrgUrl";
import { getFilterQueryStringWithoutArray } from "@/helpers/helper";

const getDefaultState = () => {
  return {
    getEmailStaticAssestsEnum: [],
    getEmailStaticAssests: [],
    getEmailStaticTemplateEnum: [],
    getEmailStaticTemplate: [],
    getEmailStaticTemplateContent: null,
    getOrgStaticAssestsEnum: [],
    getBlobExist: null,
    getEmailTemplateItem: [],
    getEmailConfiguration: [],
  };
};

const state = getDefaultState();

const getters = {
  getEmailStaticAssests: (state) => state.getEmailStaticAssests,
  getEmailStaticAssestsEnum: (state) =>
    state.getEmailStaticAssestsEnum?.map((val) => {
      return { label: val.replaceAll("_", " "), code: val.toLowerCase() };
    }) || [],
  getEmailStaticTemplate: (state) => state.getEmailStaticTemplate,
  getEmailStaticTemplateContent: (state) => state.getEmailStaticTemplateContent,
  getBlobExist: (state) => state.getBlobExist,
  getEmailStaticTemplateEnum: (state) =>
    state.getEmailStaticTemplateEnum?.map((val) => {
      if (val == "Candidate_Registration")
        return { label: val.replaceAll("_", " "), code: "welcome_user" };
      if (val == "Job_Application_Rejection")
        return {
          label: val.replaceAll("_", " "),
          code: val.replace("_Application", "").toLowerCase(),
        };
      if (val == "Password_Recovery")
        return { label: val.replaceAll("_", " "), code: "reset_password" };
      if (val == "Generic_Outbound_Email")
        return { label: val.replaceAll("_", " "), code: "outbound_communication" };
      return { label: val.replaceAll("_", " "), code: val.toLowerCase() };
    }) || [],
  getOrgStaticAssestsEnum: (state) =>
    state.getOrgStaticAssestsEnum?.map((val) => {
      if (val == "Banner_Image_Url")
        return {
          label: val.replace("_Url", "").replaceAll("_", " "),
          code: val.replace("Image", "Path").toLowerCase(),
        };
      return {
        label: val.replaceAll("_Url", "").replaceAll("_", " "),
        code: val.toLowerCase(),
      };
    }) || [],
    getEmailTemplateItem: (state) => state.getEmailTemplateItem,
    getEmailConfiguration: (state) => state.getEmailConfiguration,
};

const mutations = {
  ["SET_EMAIL_STATIC_ASSETS"](state, payload) {
    state.getEmailStaticAssests = payload;
  },
  ["SET_EMAIL_STATIC_ASSETS_ENUM"](state, payload) {
    state.getEmailStaticAssestsEnum = payload;
  },
  ["SET_EMAIL_STATIC_TEMPLATE"](state, payload) {
    state.getEmailStaticTemplate = payload;
  },
  ["SET_EMAIL_STATIC_TEMPLATE_ENUM"](state, payload) {
    state.getEmailStaticTemplateEnum = payload;
  },
  ["SET_EMAIL_STATIC_TEMPLATE_CONTENT"](state, payload) {
    state.getEmailStaticTemplateContent = payload;
  },
  ["SET_ORG_STATIC_ASSETS_ENUM"](state, payload) {
    state.getOrgStaticAssestsEnum = payload;
  },
  ["SET_CHECK_IF_URL_EXIST"](state, payload) {
    state.getBlobExist = payload;
  },
  ["SET_EMAIL_TEMPLATE_CONTENT"](state, payload) {
    state.getEmailTemplateItem = payload;
  },
  ["SET_EMAIL_CONFIGURATION"](state, payload) {
    state.getEmailConfiguration = payload;
  },
};

const actions = {
  // Email Static Assets
  fetchEmailStaticAssetsEnum({ commit }) {
    return orgConfiguration
      .getEmailStaticAssetsEnum()
      .then((res) => {
        let { data } = res;
        commit("SET_EMAIL_STATIC_ASSETS_ENUM", data);
        return res;
      })
      .catch((err) => {
        console.log("Error while fetch email static assets", err);
        return err;
      });
  },
  fetchEmailStaticAssets({ commit }, acronym) {
    return orgConfiguration
      .getEmailStaticAssets(acronym)
      .then((res) => {
        let { data } = res;
        commit("SET_EMAIL_STATIC_ASSETS", data);
        return res;
      })
      .catch((err) => {
        console.log("Error while fetch email static assets", err);
        return err;
      });
  },
  uploadEmailStaticAssets({ commit, getters, dispatch }, payload) {
    const { getEmailStaticAssestsEnum } = getters;
    let acronym = payload.acronym;
    let formData = new FormData();
    for (let i = 0; i < getEmailStaticAssestsEnum.length; i++) {
      if (payload[getEmailStaticAssestsEnum[i].code]) {
        formData.append(
          getEmailStaticAssestsEnum[i].code,
          payload[getEmailStaticAssestsEnum[i].code]
        );
      }
    }
    return orgConfiguration
      .uploadEmailStaticAssets(acronym, formData)
      .then((res) => {
        let { data } = res;
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Email assets uploaded successfully",
        });
        return res;
      })
      .catch((err) => {
        console.log("Error while fetch email static assets", err);
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error while uploading !",
        });
        return err;
      });
  },
  deleteEmailStaticAssets({ commit, dispatch }, payload) {
    let acronym = payload.acronym;
    let query = payload.query;
    return orgConfiguration
      .deleteEmailStaticAssets(acronym, query)
      .then((res) => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Email assets deleted successfully",
        });
        return res;
      })
      .catch((err) => {
        console.log("Error while fetch email static assets", err);
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error while deleting file !",
        });
        return err;
      });
  },

  // Email Static Template
  fetchEmailStaticTemplateEnum({ commit }) {
    return orgConfiguration
      .getEmailStaticTemplateEnum()
      .then((res) => {
        let { data } = res;
        commit("SET_EMAIL_STATIC_TEMPLATE_ENUM", data);
        return res;
      })
      .catch((err) => {
        console.log("Error while fetch email static template", err);
        return err;
      });
  },
  fetchEmailStaticTemplateContent({ commit }, url) {
    return orgConfiguration
      .getEmailStaticTemplateContent(url)
      .then((res) => {
        commit("SET_EMAIL_STATIC_TEMPLATE_CONTENT", res.data);
        commit("SET_CHECK_IF_URL_EXIST", res.status);
        return res;
      })
      .catch((err) => {
        commit("SET_CHECK_IF_URL_EXIST", 404);
        return 404;
      });
  },
  getStaticEmailTemplate({ commit, dispatch }, acronym) {
    return orgConfiguration
      .getStaticEmailTemplate(acronym)
      .then((res) => {
        commit("SET_EMAIL_STATIC_TEMPLATE", res.data);
        return res;
      })
      .catch((err) => {
        console.log("Error while fetch email static template", err);
        return err;
      });
  },
  uploadEmailStaticTemplate({ commit, getters, dispatch }, payload) {
    const { getEmailStaticTemplateEnum } = getters;
    let acronym = payload.acronym;
    let formData = new FormData();
    for (let i = 0; i < getEmailStaticTemplateEnum.length; i++) {
      if (payload[getEmailStaticTemplateEnum[i].code]) {
        formData.append(
          getEmailStaticTemplateEnum[i].code,
          payload[getEmailStaticTemplateEnum[i].code]
        );
      }
    }
    return orgConfiguration
      .uploadEmailStaticTemplate(acronym, formData)
      .then((res) => {
        let { data } = res;
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Email template uploaded successfully",
        });
        return res;
      })
      .catch((err) => {
        console.log("Error while fetch email static assets", err);
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error while uploading !",
        });
        return err;
      });
  },
  deleteEmailStaticTemplate({ commit, dispatch }, payload) {
    let acronym = payload.acronym;
    let query = payload.query;
    let type = payload.type;
    return orgConfiguration
      .deleteEmailStaticTemplate(acronym, query, type)
      .then((res) => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Email template deleted successfully",
        });
        return res;
      })
      .catch((err) => {
        console.log("Error while fetch email static template", err);
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error while deleting file !",
        });
        return err;
      });
  },
  // End

  //org static assets
  fetchOrgStaticAssetsEnum({ commit }) {
    return orgConfiguration
      .getOrgStaticAssetsEnum()
      .then((res) => {
        let { data } = res;
        commit("SET_ORG_STATIC_ASSETS_ENUM", data);
        return res;
      })
      .catch((err) => {
        console.log("Error while fetch org static assets", err);
        return err;
      });
  },
  fetchExistingConfigThemeFiles({ commit, getters, dispatch }, acronym) {
    return orgConfiguration
      .getExistingConfigThemeFiles(acronym)
      .then((res) => {
        let { data } = res;
        commit("ORG_CONFIG_THEME_FILES", data);
        return res;
      })
      .catch((err) => {
        console.log("error while fetching org files", err);
        return err;
      });
  },
  uploadConfigThemeFiles({ commit, getters, dispatch }, payload) {
    const { getOrgStaticAssestsEnum } = getters;
    let acronym = payload.acronym;
    let file = new FormData();
    for (let i = 0; i < getOrgStaticAssestsEnum.length; i++) {
      if (payload[getOrgStaticAssestsEnum[i].code]) {
        file.append(
          getOrgStaticAssestsEnum[i].code == "banner_path_url"
            ? getOrgStaticAssestsEnum[i].code.replace("_path_url", "")
            : getOrgStaticAssestsEnum[i].code.replace("_url", ""),
          payload[getOrgStaticAssestsEnum[i].code]
        );
      }
    }
    return dynamicOrgUrl
      .postOrgFilesByAcronym(acronym, file)
      .then((res) => {
        if (payload.header_logo_url) {
          dispatch("showToast", {
            class: "bg-success text-white",
            message: "File Uploaded Successfully",
            position: "bottom-left"
          });
        } else {
          dispatch("showToast", {
            class: "bg-success text-white",
            message: "File Uploaded Successfully",
          });
        }
        return res;
      })
      .catch((err) => {
        console.log("error while posting org files", err);
        return err;
      });
  },
  deleteConfigThemeFilesByAcronym({ commit, getters, dispatch }, payload) {
    let { acronym, documentName } = payload;
    let query = getFilterQueryStringWithoutArray({ acronym, [documentName]: true });
    return dynamicOrgUrl
      .deleteOrgFilesByAcronym(query)
      .then((res) => {
        if (documentName == 'delete_header_logo') {
          dispatch("showToast", {
            class: "bg-success text-white",
            message: "File Deleted Successfully",
            position: "bottom-left"
          });
        } else {
          dispatch("showToast", {
            class: "bg-success text-white",
            message: "File Deleted Successfully",
          });
        }
        return res;
      })
      .catch((err) => {
        console.log("error while deleting org files", err);
        return err;
      });
  },

  //Email template content
  getEmailTemplateContent({ commit, dispatch }, payload) {
    let acronym = payload.acronym;
    let template_type = payload.type;
    let query;

    if (payload.query) {
      query = `&temp_content=${payload.query}`;
    } else {
      query = `&temp_content=${payload.query}`;
    }

    return orgConfiguration
      .getEmailTemplateContent(acronym, template_type, query)
      .then((res) => {
        if(payload.query) {
          commit("SET_EMAIL_TEMPLATE_CONTENT", res.data);
        }
        return res.data;
      })
      .catch((err) => {
        console.log("Error while fetch email template content", err);
        return err;
      });
  },

  postEmailTemplateContent({ commit, dispatch }, payload) {
    let acronym = payload.acronym;
    let items = payload.items;
    let template_type = payload.type;
    return orgConfiguration
      .postEmailTemplateContent(acronym, items, template_type)
      .then((res) => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Template updated Successfully",
        });
        return res;
      })
      .catch((err) => {
        console.log("Error while fetch email template content", err);
        return err;
      });
  },

  // Email config
  postEmailConfiguration({ commit, dispatch }, payload) {
    return orgConfiguration
      .postEmailConfiguration(payload)
      .then((res) => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Email Configured Successfully",
        });
        commit("SET_EMAIL_CONFIGURATION", res.data)
        return res;
      })
      .catch((err) => {
        console.log("Error while create email configuration", err);
        return err;
      });
  },

  fetchEmailConfiguration({ commit, dispatch }, acronym) {
    return orgConfiguration
      .getEmailConfiguration(acronym)
      .then((res) => {
        commit("SET_EMAIL_CONFIGURATION", res.data[0])
        return res;
      })
      .catch((err) => {
        console.log("Error while getting email configuration", err);
        return err;
      });
  },

  updateEmailConfiguration({ commit, dispatch }, { config_id, payload }) {
    return orgConfiguration
      .updateEmailConfiguration(config_id, payload)
      .then((res) => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Email Configured Successfully",
        });
        commit("SET_EMAIL_CONFIGURATION", res.data)
        return res;
      })
      .catch((err) => {
        console.log("Error while getting email configuration", err);
        return err;
      });
  },
};

export default { state, getters, mutations, actions };
