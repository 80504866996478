<template>
  <div class="pl-2">
    <CModal
      :title="modalTitle"
      color="primary"
      :show.sync="isShowPopup"
      :closeOnBackdrop="false"
      size="lg"
    >
      <template #header>
        <h6>{{ modalTitle }}</h6>
        <CButtonClose @click="modalCallBack(false)" />
      </template>

      <template #footer>
        <CButton
          form="report_section_template"
          color="secondary"
          @click="modalCallBack('Cancel')"
          >Cancel</CButton
        >
        <CButton
          form="report_section_template"
          color="primary"
          type="Submit"
          @click="onSubmit()"
          >Confirm</CButton
        >
      </template>

      <div>
        <ValidationObserver
          ref="report_section_template"
          v-slot="{ handleSubmit }"
        >
          <form @submit.prevent="handleSubmit(onSubmit)">
            <CRow class="m-0">
              <CCol md="6" class="mb-3 pt-2">
                <CRow class="row">
                  <label class="required label-color col-lg-12 col-md-12">
                    Issue Title
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="issue_title"
                        :value="report.issue_title"
                        @input="handleInput"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="m-0">
              <CCol md="6" class="mb-3 pt-2">
                <CRow class="row">
                  <label class="required label-color col-lg-12 col-md-12">
                    Module Name
                  </label>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="module_name"
                        :value="report.module_name"
                        @input="handleInput"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="m-0">
              <CCol class="mb-3 pt-2">
                <CRow class="row">
                  <label class="required label-color col-lg-12 col-md-12">
                    Problem Description</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextAreaInput
                        name="issue_description"
                        :value="report.issue_description"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="m-0">
              <CCol class="mb-3 pt-2">
                <CRow class="row">
                  <label class="required label-color col-lg-12 col-md-12">
                    Expected Functionality</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextAreaInput
                        name="expected_functionality"
                        :value="report.expected_functionality"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>

            <!-- <CRow class="mb-3">
            <CCol md="6" class="mb-3 pt-2">
              <label class=" label-color col-12" >Upload Files</label>
              <div class="col-12">
                <input id="imageUpload" type="file" name="logo" />
              </div>
            </CCol>
          </CRow> -->
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/reusable/Modal";
import Select from "@/components/reusable/Fields/Select";
import TextInput from "@/components/reusable/Fields/TextInput";
import TextAreaInput from "@/components/reusable/Fields/TextareaInput";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });

export default {
  extends: Modal,
  name: "ReportIssuePage",
  props: ["modalCallBack", "modalTitle"],
  components: {
    Select,
    TextInput,
    TextAreaInput,
  },
  data() {
    return {
      report: {},
      payload: {},
    };
  },
  computed: {
    ...mapGetters(["getUserId", "getUser"]),
  },

  methods: {
    ...mapActions(["createReportIssue"]),
    handleChangeSelect(name, value) {
      Vue.set(this.report, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
    },
    handleInput(name, value) {
      Vue.set(this.report, name, value);
      Vue.set(this.payload, name, value);
    },
    async onSubmit() {
      try {
        const payloadData = {
          user_id: this.getUserId,
          email: this.getUser?.email,
          project_id: 1,
          ...this.payload,
        };
        await this.createReportIssue(payloadData);
        this.modalCallBack(true);
      } catch (error) {
        console.error("Error submitting the report:", error);
      }
    },
  },
};
</script>
<style>
.label-color {
  color: #000000 !important;
}
</style>
