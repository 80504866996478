import customer from '@/services/customer';
import tabFilter from '@/services/tabFilter';
import activityService from "@/services/activityList";
import _ from 'lodash'
import Vue from 'vue'
import job from '../services/job';
import { getFilterQueryStringWithoutArray } from "@/helpers/helper";
import dashboard from '@/services/dashboard';

const getDefaultState = () => {
  return {
    getScratchPads: [],
    getScratchPad: null,
    getTabTotalCounts: [],
    getTabStatusTotalCounts: [],
    getActionDetailsByRecruiter: [],
    getSubmissionAction: [],
    getInterviewRequestAction: [],
    getInterviewScheduleAction: [],
    getJobApprovalAction: [],
    getJobsForDashboard: [],
    getJobsForAccordion: [],
    interviewSlots: [],
    offerActionDetails: [],
    onboardActionDetails: [],
    joiningActionDetails: [],
    getJobPendingAction: [],
    dashboardTabCount: {
      job_approval: 0,
      submission: 0,
      schedule: 0,
      interview: 0,
      accordion: 0,
      offered: 0,
      onboard: 0,
      joining: 0,
      cv_request: 0,
    },
    collapseIndex: null,
    activeDashboardTab: 0,
    getAgencyCVRequest: [],
    cvRequest: [],
    lastTransactions: [],
  };
}

const state = getDefaultState()


const getters = {
  getScratchPads: state => _.orderBy(state.getScratchPads, ['date_added'], ['desc']),
  getScratchPad: state => state.getScratchPad,
  getTabTotalCounts: state => state.getTabTotalCounts,
  getTabStatusTotalCounts: state => state.getTabStatusTotalCounts,
  getActionDetailsByRecruiter: state => state.getActionDetailsByRecruiter,
  getSubmissionAction: state => state.getSubmissionAction || [],
  getInterviewRequestAction: state => state.getInterviewRequestAction || [],
  getInterviewScheduleAction: state => state.getInterviewScheduleAction || [],
  getJobPendingAction: state => state.getJobPendingAction || [],
  getJobApprovalAction: state => state.getJobApprovalAction || [],
  getJobsForDashboard: state => state.getJobsForDashboard || [],
  getJobsForAccordion: state => state.getJobsForAccordion || [],
  getInterviewSlots: state => state.interviewSlots || [],
  getDashboardTabCount: state => state.dashboardTabCount,
  getCollapseIndex: state => state.collapseIndex,
  getActiveDashboardTab: state => state.activeDashboardTab,
  getOfferActionDetails: state => state.offerActionDetails,
  getOnboardActionDetails: state => state.onboardActionDetails,
  getJoiningActionDetails: state => state.joiningActionDetails,
  getAgencyCVRequest: state => state.getAgencyCVRequest,
  getCVRequest: state => state.cvRequest,
  getLastTransactions: state => state.lastTransactions,
};

const mutations = {
  ["SET_SCRATCH_PADS"](state, payload) {
    state.getScratchPads = payload;
  },
  ["SET_SCRATCH_PAD"](state, payload) {
    state.getScratchPad = payload;
  },
  ["addScratchPads"](state, payload) {
    state.getScratchPads.push(payload)
  },
  ["updateScratchPads"](state, payload) {
    if (state.getScratchPads) {
      const i = _.findIndex(state.getScratchPads, {
        id: payload.id
      })
      Vue.set(state.getScratchPads, i, payload)
    }
  },
  ["deleteScratchPad"](state, id) {
    const i = _.findIndex(state.getScratchPads, { id })
    if (i > -1) state.getScratchPads.splice(i, 1);
  },
  ["SET_TAB_TOTAL_COUNT"](state, payload) {
    state.getTabTotalCounts = payload;
  },
  ["SET_STATUS_TOTAL_COUNT"](state, payload) {
    state.getTabStatusTotalCounts = payload;
  },
  ["SET_RECRUITER_ACTION_DETAILS"](state, payload) {
    state.getActionDetailsByRecruiter = payload;
  },
  ['SET_SUBMISSION_ACTION'](state, payload) {
    state.getSubmissionAction = payload;
  },
  ['SET_INTERVIEW_REQUEST_ACTION'](state, payload) {
    state.getInterviewRequestAction = payload;
  },
  ["SET_INTERVIEW_SCHEDULED_ACTION_DETAILS"](state, payload) {
    state.getInterviewScheduleAction = payload;
  },
  ["SET_JOB_PENDING_ACTION_DETAILS"](state, payload) {
    state.getJobPendingAction = payload;
  },
  ['SET_JOB_APPROVAL_ACTION'](state, payload) {
    state.getJobApprovalAction = payload;
  },
  ['SET_JOBS_FOR_DASHBOARD'](state, payload) {
    state.getJobsForDashboard = payload;
  },
  ['SET_JOBS_FOR_ACCORDION'](state, payload) {
    state.getJobsForAccordion = payload;
  },
  ['SET_INTERVIEW_SLOT'](state, payload) {
    state.interviewSlots = payload
  },
  ['SET_DASHBOARD_TAB_COUNT'](state, payload) {
    state.dashboardTabCount = { ...state.dashboardTabCount, ...payload }
  },
  ['SET_DASHBOARD_COLLAPSE_INDEX'](state, payload) {
    state.collapseIndex = payload
  },
  ['SET_DASHBOARD_ACTIVE_TAB'](state, payload) {
    state.activeDashboardTab = payload
  },
  ['SET_OFFER_ACTION_DETAILS'](state, payload) {
    state.offerActionDetails = payload
  },
  ['SET_ONBOARD_ACTION_DETAILS'](state, payload) {
    state.onboardActionDetails = payload
  },
  ['SET_JOINING_ACTION_DETAILS'](state, payload) {
    state.joiningActionDetails = payload
  },
  ['SET_AGENCY_CV_REQUEST'](state, payload) {
    state.getAgencyCVRequest = payload
  },
  ['SET_CV_REQUEST'](state, payload) {
    state.cvRequest = payload;
  },
  ['SET_LAST_TRANSACTIONS'](state, payload){
    state.lastTransactions = payload;
  }
};

const actions = {
  setScratchPad: ({ state, commit, rootState, dispatch, getters }, payload) => {
    commit('SET_SCRATCH_PAD', payload)
  },
  getScratchPadItems: ({ state, commit, rootState, dispatch, getters }) => {
    const {
      getCustomerId,
      getUserId
    } = getters;
    return customer
      .getScratchPadItems(getCustomerId, getUserId)
      .then(async res => {
        commit("SET_SCRATCH_PADS", res.data);
      })
      .catch(err => {
        console.log("error fetching candidate by type", err);
        return err;
      });
  },
  postScratchPad: ({ state, commit, rootState, dispatch, getters }, payload) => {
    return customer
      .postScratchPad(payload.customer_uid, payload.user_id, payload)
      .then(async res => {
        dispatch("showToast", {
          class: 'bg-success text-white',
          message: 'Item saved successfully'
        })
        commit('addScratchPads', res.data)
      })
      .catch(err => {
        console.log("error fetching candidate by type", err);
        return err;
      });
  },
  putScratchPad: ({ state, commit, rootState, dispatch, getters }, payload) => {
    return customer
      .putScratchPad(payload.customer_uid, payload.user_id, payload)
      .then(async res => {
        commit('updateScratchPads', res.data)
        dispatch("showToast", {
          class: 'bg-success text-white',
          message: 'Item updated successfully'
        })
      })
      .catch(err => {
        console.log("error fetching candidate by type", err);
        return err;
      });
  },
  deleteScratchpad: ({ state, commit, rootState, dispatch, getters }, payload) => {
    return customer
      .deleteScratchpad(payload.customer_uid, payload.user_id, payload)
      .then(async res => {
        commit('deleteScratchPad', payload.id)
        dispatch("showToast", {
          class: 'bg-success text-white',
          message: 'Item deleted successfully'
        })
      })
      .catch(err => {
        console.log("error fetching candidate by type", err);
        return err;
      });
  },
  jobCandidateTabTotalCount: ({ state, commit, getters }, customer_id) => {
    let { getIsChildUser, getOrgID: org_id } = getters;
    let queryString = !getIsChildUser ? `customer_id=${customer_id}` : `customer_id=${customer_id}&organisation_id=${org_id}`;
    return tabFilter.jobCandidateTabTotalCount(queryString, getIsChildUser).then((res) => {
      let tabCounts = [];
      let statusCounts = [];
      _.forEach(res.data, (tabDetail) => {
        let tabCount = {
          displayName: tabDetail?.tab_.tab_name,
          name: tabDetail?.tab_.tab_name.split(" ").join("").toLowerCase(),
          id: 1,
          count: tabDetail?.count,
          value: tabDetail?.count,
          urlToJobStatus: `tab=${tabDetail?.tab_id}&tabFilter=2`
        }
        tabCounts.push(tabCount);
        _.forEach(tabDetail?.filters, status => {
          let statusCount = {
            displayName: status?.filter_.filter_name,
            name: status?.filter_.filter_name.split(" ").join("").toLowerCase(),
            id: status?.filter_?.id,
            count: status?.count,
            value: status?.count,
            urlToJobStatus: `tab=${tabDetail?.tab_id}&tabFilter=${status?.filter_?.id}`
          }
          statusCounts.push(statusCount);
        });

      });
      commit("SET_TAB_TOTAL_COUNT", tabCounts);
      commit("SET_STATUS_TOTAL_COUNT", statusCounts);
      return true;
    }).catch((err) => {
      console.log('error while fetching tab total count: ' + err);
      return err;
    });
  },
  getRecruiterActionDetails: ({ commit, getters }) => {
    const { getUserId: user_id } = getters
    return job.getActionsForRecruiter({ user_id })
      .then(res => {
        const { data } = res
        commit("SET_RECRUITER_ACTION_DETAILS", data)
      })
      .catch(err => {
        console.log("Error in fetching action details", err)
        return err
      })
  },
  fetchSubmissionActionDetails: ({ commit, getters }) => {
    const { getUserId: user_id, getOrgIDFromAccessToken: organisation_id, getIsChildUserFromAccessToken, getCustomerId: customer_uid } = getters;
    let queryPayload = {};
    if (customer_uid)
      queryPayload = { ...queryPayload, customer_uid };
    if (organisation_id && getIsChildUserFromAccessToken)
      queryPayload = { ...queryPayload, organisation_id };
    const queryString = getFilterQueryStringWithoutArray(queryPayload)
    return job.getSubmissionActionDetails({ queryString })
      .then(res => {
        const { data } = res;
        commit("SET_SUBMISSION_ACTION", data);
        return data;
      })
      .catch(err => {
        console.log("Error While Fetching Submission Action", err);
        return err;
      })
  },
  fetchInterviewRequestAction: ({ commit, getters }) => {
    const { getUserId: user_id, getOrgIDFromAccessToken: organisation_id, getIsChildUserFromAccessToken, getCustomerId: customer_uid } = getters;
    let queryPayload = {};
    if (customer_uid)
      queryPayload = { ...queryPayload, customer_uid };
    if (organisation_id && getIsChildUserFromAccessToken)
      queryPayload = { ...queryPayload, organisation_id };
    const queryString = getFilterQueryStringWithoutArray(queryPayload)
    return job.getInterviewRequestAction({ queryString })
      .then(res => {
        const { data } = res;
        commit("SET_INTERVIEW_REQUEST_ACTION", data);
        return data;
      })
      .catch(err => {
        console.log("Error While Fetching Interview Request Action", err);
        return err;
      })
  },
  getInterviewScheduledCandidateActionDetails: ({ commit, getters }) => {
    const { getUserId: user_id, getCustomerId: customer_uid, getIsChildUserFromAccessToken, getOrgIDFromAccessToken: organisation_id } = getters;
    let queryPayload = {};
    if (customer_uid)
      queryPayload = { ...queryPayload, customer_uid };
    if (organisation_id && getIsChildUserFromAccessToken)
      queryPayload = { ...queryPayload, organisation_id };
    const queryString = getFilterQueryStringWithoutArray(queryPayload)
    return job.getInterviewScheduledCandidateActionDetails({ queryString })
      .then(res => {
        const { data } = res;
        commit("SET_INTERVIEW_SCHEDULED_ACTION_DETAILS", data);
        return data;
      })
      .catch(err => {
        console.log("Error While Fetching Interview Scheduled Action", err);
        return err;
      })
  },
  getJobPendingActionDetails: ({ commit, getters }) => {
    const { getUserId: user_id, getIsChildUser, getOrgID: org_id, getCustomerId: customer_id } = getters;
    let queryString = !getIsChildUser ? `customer_uid=${customer_id}` : `customer_uid=${customer_id}&organisation_id=${org_id}`;
    return job.getJobPendingActionDetails({ queryString })
      .then(res => {
        const { data } = res;
        commit("SET_JOB_PENDING_ACTION_DETAILS", data);
        return data;
      })
      .catch(err => {
        console.log("Error While Fetching Interview Scheduled Action", err);
        return err;
      })
  },
  fetchJobApprovalAction({ commit, getters }) {
    const { getUserDetails, getOrgIDFromAccessToken: organisation_id, getIsChildUserFromAccessToken, getCustomerId: customer_uid, isCandidateSupplierFromAccessToken, getUserContactId } = getters;
    let queryPayload = {};
    if (customer_uid)
      queryPayload = { ...queryPayload, customer_uid };
    if (getIsChildUserFromAccessToken) {
      if (organisation_id)
        queryPayload = { ...queryPayload, organisation_id };
    }
    const queryString = getFilterQueryStringWithoutArray(queryPayload);
    return job.getJobApprovalAction({ queryString })
      .then(res => {
        const { data } = res;
        commit("SET_JOB_APPROVAL_ACTION", data);
        return data;
      })
      .catch(err => {
        console.log("Error While Fetching Interview Scheduled Action", err);
        return err;
      })
  },
  fetchJobsForDashboard({ commit, getters }) {
    const { getCustomerId: customer_id, getIsChildUserFromAccessToken, isCandidateSupplierFromAccessToken, getOrgIDFromAccessToken: org_id } = getters;
    let query = "";
    if (org_id && getIsChildUserFromAccessToken) query = `&organisation_uid=${org_id}`
    return job.fetchJobCards({ customer_id, query })
      .then(res => {
        const { data } = res;
        commit("SET_JOBS_FOR_DASHBOARD", data);
        return data;
      })
      .catch(err => {
        console.log("Error", err);
        return err;
      })
  },
  fetchTransactionsByJobId({ commit, getters }, payload) {
    let { job_id, action_id, candidate_uid, status_id__in, sub_status_id__in } = payload;
    let queryPayload = { job_id, action_id };
    if (candidate_uid) queryPayload['candidate_uid'] = candidate_uid;
    if (status_id__in) queryPayload['status_id__in'] = status_id__in;
    if (sub_status_id__in) queryPayload['sub_status_id__in'] = sub_status_id__in;
    let query = '&' + getFilterQueryStringWithoutArray(queryPayload);
    commit("SET_JOBS_FOR_ACCORDION", []);
    return job.getJobApprovalTransactionsByJobId(query)
      .then(res => {
        const { data } = res;
        commit("SET_JOBS_FOR_ACCORDION", data);
        return data;
      })
      .catch(err => {
        console.log("Error", err);
        return err;
      })
  },
  updateExpiryDate({ commit, dispatch, getters }, payload) {
    const { organisation_uid, customer_uid, ...restPayload } = payload;
    dispatch('showToast', { message: 'Updating...' });
    return job.updateJobs(restPayload, customer_uid, organisation_uid)
      .then(res => {
        const { data } = res;
        dispatch('showToast', {
          class: 'bg-success text-white',
          message: 'Updated successfully',
        });
        return data;
      })
      .catch(err => {
        console.log("Error", err);
        dispatch('showToast', { class: 'bg-danger text-white', message: "Error while saving!" });
        return err;
      })
  },
  updateExpiryDate({ commit, dispatch, getters }, payload) {
    const { organisation_uid, customer_uid, ...restPayload } = payload;
    dispatch('showToast', { message: 'Updating...' });
    return job.updateJobs(restPayload, customer_uid, organisation_uid)
      .then(res => {
        const { data } = res;
        dispatch('showToast', {
          class: 'bg-success text-white',
          message: 'Updated successfully',
        });
        return data;
      })
      .catch(err => {
        console.log("Error", err);
        dispatch('showToast', { class: 'bg-danger text-white', message: "Error while saving!" });
        return err;
      })
  },
  markAsCompleted({ commit, dispatch, getters }, payload) {
    const { getCustomerId } = getters;
    const { action_detail_id } = payload;
    dispatch('showToast', { message: 'Updating...' });
    return job.markAsCompleted({ action_detail_id: action_detail_id, customer_id: getCustomerId })
      .then(res => {
        const { data } = res;
        dispatch('showToast', {
          class: 'bg-success text-white',
          message: 'Updated successfully',
        });
        return data;
      })
      .catch(err => {
        console.log("Error", err);
        dispatch('showToast', { class: 'bg-danger text-white', message: "Error while saving!" });
        return err;
      })
  },
  fetchInterviewSlot({ commit, getters }) {
    const { getCustomerId: customer_uid } = getters;
    let queryPayload = {}
    queryPayload = {
      ...queryPayload,
      status_id__in: [54],
      sub_status_id__in: [55],
      completed_by__in: 'null',
      customer_uid,
      date_field: 'initiated_date',
    }
    const queryString = getFilterQueryStringWithoutArray(queryPayload);
    return activityService
      .jobCandidateTransaction({ queryString })
      .then(res => {
        const { data } = res;
        commit("SET_INTERVIEW_SLOT", data);
        return data
      })
      .catch(err => {
        console.log("Error while fetch display status", err)
        return err
      })
  },
  fetchOfferActionDetails: ({ commit, getters }) => {
    const { getUserId: user_id, getOrgIDFromAccessToken: organisation_id, getIsChildUserFromAccessToken, getCustomerId: customer_uid } = getters;
    let queryPayload = {};
    if (customer_uid)
      queryPayload = { ...queryPayload, customer_uid };
    if (organisation_id && getIsChildUserFromAccessToken)
      queryPayload = { ...queryPayload, organisation_id };
    const queryString = getFilterQueryStringWithoutArray(queryPayload)
    return job.offerActionDetails({ queryString })
      .then(res => {
        const { data } = res;
        commit("SET_OFFER_ACTION_DETAILS", data);
        return data;
      })
      .catch(err => {
        console.log("Error While Fetching Offer Action", err);
        return err;
      })
  },
  fetchOnBoardActionDetails: ({ commit, getters }) => {
    const { getUserId: user_id, getOrgIDFromAccessToken: organisation_id, getIsChildUserFromAccessToken, getCustomerId: customer_uid } = getters;
    let queryPayload = {};
    if (customer_uid)
      queryPayload = { ...queryPayload, customer_uid };
    if (organisation_id && getIsChildUserFromAccessToken)
      queryPayload = { ...queryPayload, organisation_id };
    const queryString = getFilterQueryStringWithoutArray(queryPayload)
    return job.onboardActionDetails({ queryString })
      .then(res => {
        const { data } = res;
        commit("SET_ONBOARD_ACTION_DETAILS", data);
        return data;
      })
      .catch(err => {
        console.log("Error While Fetching Onboard Action", err);
        return err;
      })
  },
  fetchJoiningActionDetails: ({ commit, getters }) => {
    const { getUserId: user_id, getOrgIDFromAccessToken: organisation_id, getIsChildUserFromAccessToken, getCustomerId: customer_uid } = getters;
    let queryPayload = {};
    if (customer_uid)
      queryPayload = { ...queryPayload, customer_uid };
    if (organisation_id && getIsChildUserFromAccessToken)
      queryPayload = { ...queryPayload, organisation_id };
    const queryString = getFilterQueryStringWithoutArray(queryPayload)
    return job.joiningActionDetails({ queryString })
      .then(res => {
        const { data } = res;
        commit("SET_JOINING_ACTION_DETAILS", data);
        return data;
      })
      .catch(err => {
        console.log("Error While Fetching Offer Action", err);
        return err;
      })
  },
  update_job_expiryDate: ({ commit, dispatch, getters }, payload) => {
    const { action_detail_id } = payload;
    return job.updateJobsExpiryDate(action_detail_id, payload)
      .then(res => {
        const { data } = res;
        dispatch('showToast', {
          class: 'bg-success text-white',
          message: 'Updated successfully',
        });
        return data;
      })
      .catch(err => {
        console.log("Error", err);
        dispatch('showToast', { class: 'bg-danger text-white', message: "Error while saving!" });
        return err;
      })
  },
  fetchAgencyTransaction: ({ commit, getters }) => {
    const { getUserId: user_id, isUKMainAgency, isJobSupplierFromAccessToken, getIsChildUser, getOrgID: organisation_id, getCustomerId: customer_uid } = getters;
    let queryPayload = {};

    if (isUKMainAgency)
      queryPayload = { ...queryPayload, date_uploaded__isnull: true, all_rows: true };
    else if (isJobSupplierFromAccessToken)
      queryPayload = { ...queryPayload, close: false, all_rows: true };

    const query = getFilterQueryStringWithoutArray(queryPayload);
    return job.getAgencyTransaction(query)
      .then(res => {
        const { data } = res;
        commit("SET_AGENCY_CV_REQUEST", data);
        return data;
      })
      .catch(err => {
        console.log("Error While Fetching Offer Action", err);
        return err;
      })
  },
  updateAgencyTransaction: ({ commit, getters, dispatch }, { trans_id, payload }) => {
    const { getUserId: user_id, isUKMainAgency, isJobSupplierFromAccessToken } = getters;
    return job.updateAgencyTransaction(trans_id, payload)
      .then(res => {
        const { data } = res;
        dispatch('showToast', {
          class: 'bg-success text-white',
          message: "Agency CV Request Mark Completed..!"
        });
        return data;
      })
      .catch(err => {
        console.log("Error While Fetching Offer Action", err);
        return err;
      })
  },
  resetAlertModal({ commit }) {
    commit("SET_JOB_APPROVAL_ACTION", []);
    commit("SET_SUBMISSION_ACTION", []);
    commit("SET_INTERVIEW_REQUEST_ACTION", [])
    commit("SET_INTERVIEW_SCHEDULED_ACTION_DETAILS", []);
    commit("SET_JOB_PENDING_ACTION_DETAILS", []);
    commit("SET_JOBS_FOR_DASHBOARD", []);
    commit("SET_ONBOARD_ACTION_DETAILS", []);
    commit("SET_JOINING_ACTION_DETAILS", []);
  },
  updateJobDetailExpiryDate: ({ commit, getters, dispatch }, data) => {
    const { customer_uid, ...resetPayload } = data;
    return job
      .updateJobDetailExpiryDate({ customer_uid, payload: resetPayload })
      .then(res => {
        const { data } = res;
        dispatch('showToast', {
          class: 'bg-success text-white',
          message: 'Updated successfully',
        });
        return data;
      })
      .catch(err => {
        const message = "Error While Updating Job Expiry Date";
        console.log(message, err);
        dispatch('showToast', { class: 'bg-danger text-white', message });
      })
  },
  fetchCVRequest({ commit, getters, dispatch }, payload = {}) {
    const queryString = getFilterQueryStringWithoutArray(payload);
    return job
      .getAgencyTransaction(queryString)
      .then(res => {
        const { data } = res;
        commit("SET_CV_REQUEST", data)
        return data;
      })
      .catch(err => {
        const message = "Error while Fetch CV Request";
        console.log(message, err);
        dispatch("showToast", { class: 'bg-danger text-white', message })
        return err;
      })
  },
  fetchLastTransactions({ commit, getters, dispatch }) {
    const { getUserId: user_id } = getters;
    const queryPayload = {
      user_id,
      order_by: '-created_on',
      all_rows: false,
      fetch_row_count: false,
    }
    const queryString = getFilterQueryStringWithoutArray(queryPayload)
    return dashboard
      .fetchLastTransactions(queryString)
      .then(res => {
        const { data } = res;
        commit("SET_LAST_TRANSACTIONS", data);
        return res;
      })
      .catch(err => {
        const message = "Error while Fetch Recent Transactions";
        console.log(message, err)
        dispatch("showToast", { class: 'bg-danger text-white', message })
        return err;
      })
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
