<template>
  <div class="landing-page position-relative">
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
    <Section5 />
    <Section5a />
    <Section6 />
    <Section7 />
    <Section8 ref="section-8" />
    <button
      v-if="isScrollTop"
      @click="scrollToTop()"
      id="btn-back-to-top"
      class="btn btn-primary btn-floating btn-lg"
      title="Go to top">
      <i class="fas fa-arrow-up"></i>
    </button>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Section1 from "@/containers/TFW/LandingPage/Section1";
import Section2 from "@/containers/TFW/LandingPage/Section2";
import Section3 from "@/containers/TFW/LandingPage/Section3";
import Section4 from "@/containers/TFW/LandingPage/Section4";
import Section5 from "@/containers/TFW/LandingPage/Section5";
import Section5a from "@/containers/TFW/LandingPage/Section5a";
import Section6 from "@/containers/TFW/LandingPage/Section6";
import Section7 from "@/containers/TFW/LandingPage/Section7";
import Section8 from "@/containers/TFW/LandingPage/Section8";
export default {
  name: "landingPageTFW",
  components: {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section5a,
    Section6,
    Section7,
    Section8,
  },
  data() {
    return {
      isScrollTop: false,
    };
  },
  methods: {
    ...mapActions(["scrollTop"]),
    onBodyScroll(e) {
      if (e.target.scrollTop > 20) {
        this.isScrollTop = true;
      } else {
        this.isScrollTop = false;
      }
    },
    scrollToTop() {
      this.scrollTop();
    },
  },
  mounted() {
    const { hash } = this.$router.currentRoute;
    if (hash) {
      const refID = hash.replace("#", "");
      const element = document.getElementById(refID);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    } else this.scrollTop();
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
  },
  beforeDestroy() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
};
</script>
<style scoped>
@media screen and (max-width: 900px) {
  .landing-page {
    overflow-x: hidden;
  }
}
</style>
