import tfw from "@/services/tfw";
import { getFilterQueryStringWithoutArray, Role } from "@/helpers/helper.js";
const getDefaultState = () => {
  return {
    getDashboardCountryCount: null,
    tfwCandidateCount: null,
  };
}

const state = getDefaultState()

const getters = {
  getDashboardCountryCount: (state) => state.getDashboardCountryCount || [],
  getTFWCandidateCount: (state) => state.tfwCandidateCount || 0,
};

const mutations = {
  ["SET_DASHBOARD_COUNTRY_COUNT"](state, data) {
    state.getDashboardCountryCount = data;
  },
  ["SET_TFW_CANDIDATE_COUNT"](state, data) {
    state.tfwCandidateCount = data;
  }
};
const actions = {
  fetchDashboardCountryCount: ({ state, commit, getters, dispatch }, customer_uid) => {
      return tfw
      .getDashboardCountryCount({ customer_uid })
      .then((res) => {
        const { data } = res;
        commit("SET_DASHBOARD_COUNTRY_COUNT", data);
        return res;
      })
      .catch((err) => {
        const message = "Error while Fetch Applcation Count";
        dispatch("showToast", {
          message,
          class: "text-white bg-danger",
        });
        return err;
      });
  },
  fetchTFWCandidateCount: ({ commit, dispatch, getters }, customer_id) => {
    return tfw
      .fetchTFWCandidateCount({ customer_id })
      .then(res => {
        const { data } = res;
        commit("SET_TFW_CANDIDATE_COUNT", data);
        return data;
      })
      .catch(err => {
        console.log("Error while Fetch Candidate Count", err);
        return err;
      })
  }

};

export default { state, getters, mutations, actions };