import recruitingAgency from '@/services/recruitingAgency';
import {getCustomerId} from "@/helpers/helper";
const state = {

}

const getters = {

}

const actions = {
    addRecruitingAgencyOrganisations({ dispatch }, payload) {
        let {customer_id, data} = payload
        return recruitingAgency.addOrganisations({customer_id , data}).then(res => {
            dispatch("showToast", {
                class: "bg-success text-white",
                message: "added successfully"
            });
            return res;
        }).catch(err => {
            if (err?.response?.status === 400) {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "error while adding"
                })
            }
            console.log("error while adding organisations", err);
            return err;
        })
    },
    deleteRecruitingAgencyOrganisations({ dispatch }, payload) {
        let {customer_id, data} = payload
        return recruitingAgency.removeOrganisations({customer_id, data}).then(res => {
            dispatch("showToast", {
                class: "bg-success text-white",
                message: "deleted successfully"
            });
            return res;
        }).catch(err => {
            if (err?.response?.status === 400) {
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message: "error while deleting"
                })
            }
            console.log("error while removing organisations", err);
            return err;
        })
    }
}

const mutations = {

}

export default {
    state,
    getters,
    actions,
    mutations,
};