import languageScore from "@/services/languageScore";
import * as _ from "lodash";
import m from "moment";

const state = {
  languageExams: [],
  languageExamScores: [],
  candidateTestScores: [],
  testSections: [],
  testScoresList: [],
  testExamTypes: [],
};

const getters = {
  getLanguageExam: (state) => state.languageExams,
  getLanguageExamScores: (state) => state.languageExamScores,
  getCandidateTestScores: (state, getters) => {
    const { getLocationCountryInfo, locationLanguage } = getters;

    const scoresByDate = {};

    const data = state.candidateTestScores.map(v => ({
      ...v,
      _date_obtained: m(v.date_obtained, "YYYY-MM-DD").toDate()
    })).sort((a, b) => {
      return b._date_obtained - a._date_obtained
    })

    data.forEach((item) => {
      const dateObtained = item.date_obtained;
      const countryInfo = getLocationCountryInfo.find(
        (country) => country.country_id === item.country_obtained_id
      );
      const uniqueId = `${item.test_id}_${item.language_id}`;

      const languageInfo = locationLanguage.find(
        (language) => language.code === item.language_id
      );

      if (!scoresByDate[uniqueId]) {
        scoresByDate[uniqueId] = {
          date_obtained: dateObtained,
          country_name: countryInfo ? countryInfo.country_name : null,
          test_type: item.test_type,
          language_exam: item.test.test_name,
          test_acronym: item.test.test_acronym,
          skills: [],
          language: item.language?.language,
          avg_score: 0,
          official_test_booked: item.official_test_booked,
          test_id: item.test_id,
          _rawData: item,
          predicted_date: item.predicted_date
        };
      }

      scoresByDate[uniqueId].avg_score = Number(((scoresByDate[uniqueId].avg_score + parseFloat(item.score)) / 2).toFixed(2))
      scoresByDate[uniqueId].skills.push({
        skills: item.test_section.section_name,
        grade: item.test_score.grade,
        start_score: item.test_score.start_score,
        end_score: item.test_score.end_score,
        score: item.score,
        _rawData: item,
        unique_test_score_no: item.unique_test_score_no,
      });
      if (item.predicted_date) {
        scoresByDate[uniqueId].predicted_date = item.predicted_date
      }
    });
    const scores = Object.values(scoresByDate);
    const res = scores.map(v => {
      const { skills, ...rest } = v;

      const groupedArr = Object.values(skills.reduce(function (test, x) {
        const key = `${x.unique_test_score_no}_${x._rawData.date_obtained}`
        if (!test[key]) { test[key] = []; }
        test[key].push(x);
        return test;
      }, {}));

      const groupedSkill = groupedArr.map(v => {
        const scores = v.map(e => e.score);
        const sumScore = scores.reduce((a, b) => a + b, 0);
        let avg = (sumScore / scores.length) || 0;
        const test_id = v?.[0]?._rawData?.test_id;
        if (test_id === 2) {
          avg = Math.round(v.reduce((a, b) => (parseFloat(b.grade) * 2) + a, 0) / v.length) / 2;
        }
        return {
          date_obtained: v[0]._rawData.date_obtained,
          test_type: v[0]._rawData.test_type,
          avg,
          skills: v
        }
      })
      const temp = {
        ...rest,
        current_score: groupedSkill[0]?.avg || null,
        skills: groupedSkill,
      }
      return temp
    });
    return res;
  },
  getTestSections: (state) => state.testSections,
  getTestScoresList: (state) => state.testScoresList,
  getOverallResult: (state) => ({ test_id, score }) => {
    const testScoreData = state.testScoresList;
    const resultData = testScoreData.filter(v => v.test_id === test_id);
    const res = resultData?.find(v =>
    (test_id != 2 ? (
      (v.end_score ? v.end_score >= score : v.start_score == score) &&
      v.start_score <= score) : v.grade == score)
    ) || null
    return res;
  },
  getTestExamTypes: (state) => state.testExamTypes.map(v => ({ code: v.test_exam_id, label: v.test_exam_type })) || [],
};

const mutations = {
  ["SET_LANGUAGE_EXAMS"](state, data) {
    state.languageExams = data;
  },
  ["SET_LANGUAGE_EXAM_SCORES"](state, data) {
    state.languageExamScores = data;
  },
  ["SET_CANDIDATE_LANGUAGE_EXAM_SCORES"](state, data) {
    state.candidateTestScores = data;
  },
  ["SET_TEST_SECTIONS"](state, data) {
    state.testSections = data;
  },
  ["SET_TEST_SCORES_LIST"](state, data) {
    state.testScoresList = data;
  },
  ["SET_TEST_EXAM_TYPES"](state, data) {
    state.testExamTypes = data;
  }
};

const actions = {
  fetchLanguageExam({ commit }) {
    return languageScore
      .getLanguageExam()
      .then((res) => {
        commit("SET_LANGUAGE_EXAMS", res.data);
        return res;
      })
      .catch((err) => {
        console.log("Error while fetching language exams", err);
        return err;
      });
  },
  fetchLanguageExamScores({ commit }, test_id) {
    return languageScore
      .getLanguageExamScores(test_id)
      .then((res) => {
        commit("SET_LANGUAGE_EXAM_SCORES", res.data);
        return res;
      })
      .catch((err) => {
        console.log("Error while fetching language exams scores", err);
        return err;
      });
  },
  createCandidateTestScore: ({ dispatch }, payload) => {
    return languageScore
      .createCandidateTestScore(payload)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        const message = err.response.data.detail;
        dispatch("showToast", {
          class: "bg-danger text-white",
          message,
        });
        console.log(message, err);
        return err;
      });
  },
  fetchCandidateTestScores: ({ commit, getters }) => {
    let { candidateProfile } = getters;

    const { candidate_uid } = candidateProfile

    return languageScore
      .getCandidateTestScores(candidate_uid)
      .then((res) => {
        if(res.status === 200) {
          const { data } = res;
          commit("SET_CANDIDATE_LANGUAGE_EXAM_SCORES", (data ?? []));
          return res;
        } else if(res.status === 400) {
          commit("SET_CANDIDATE_LANGUAGE_EXAM_SCORES", []);
          return res;
        }
      })
      .catch((err) => {
        commit("SET_CANDIDATE_LANGUAGE_EXAM_SCORES", []);
        console.log(
          "Error while fetching candidate language exams scores",
          err
        );
        return err;
      });
  },
  fetchTestSections: ({ commit, dispatch }) => {
    dispatch("showLoader");
    return languageScore
      .getTestSection()
      .then(res => {
        const { data } = res;
        commit("SET_TEST_SECTIONS", data);
        dispatch("hideLoader");
        return res;
      })
      .catch(err => {
        const message = "Error while fetch Test Section";
        console.log(message, err);
        dispatch("showToast", {
          class: "bg-danger text-white",
          message,
        })
        return err;
      })
  },
  updateTestSection: ({ commit, dispatch, getters }, payload) => {
    const { getCandidateId } = getters
    let finalPayload = {
      ...payload,
      candidate_uid: getCandidateId
    }
    const { test_id, section_data, candidate_uid } = finalPayload
    dispatch("showLoader")
    return languageScore
      .updateTestSection(test_id, section_data, candidate_uid)
      .then((res) => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Scores Updated Successfully",
        });
        dispatch("hideLoader");
        return res;
      })
      .catch((err) => {
        const message = err.response.data.detail;
        dispatch("showToast", {
          class: "bg-danger text-white",
          message,
        });
        console.log(message, err);
        return err;
      });
  },
  deleteCandidateTest: ({ commit, dispatch }, candidateTestId) => {
    return languageScore
      .deleteCandidateTest(candidateTestId)
      .then(res => {
        return res;
      })
      .catch((err) => {
        const message = err.response.data.detail;
        dispatch("showToast", {
          class: "bg-danger text-white",
          message,
        });
        console.log(message, err);
        return err;
      });
  },
  fetchTestScores: ({ commit, dispatch }) => {
    return languageScore
      .getTestScores()
      .then(res => {
        const { data } = res;
        commit("SET_TEST_SCORES_LIST", data);
        return res;
      })
      .catch((err) => {
        const message = err.response.data.detail;
        dispatch("showToast", {
          class: "bg-danger text-white",
          message,
        });
        console.log(message, err);
        return err;
      });
  },
  fetchTestExamTypes: ({ commit }) => {
    return languageScore
      .getTestExamTypes()
      .then(res => {
        const { data } = res;
        commit("SET_TEST_EXAM_TYPES", data);
        return res;
      })
      .catch(err => {
        const message = "Error while fetch Exam Test Types";
        dispatch("showToast", {
          class: "bg-danger text-white",
          message,
        });
        console.log(message, err);
        return err;
      })
  }
};

export default { state, getters, mutations, actions };
