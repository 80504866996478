import preScreening from "@/services/preScreening";
import { getFilterQueryStringWithoutArray } from "@/helpers/helper";
import _ from "lodash";

const getDefaultState = () => {
    return {
        questionTypes: [],
        preScreeningQuestions: [],
        preScreeningResponses: [],
        isFetchingTransactions: false,
        preScreeningTransactions: [],
    }
}

const state = getDefaultState();

const getters = {
    questionTypes: state => state.questionTypes && state.questionTypes.filter(v => v.question_type_name !== "text_area").map(v => ({
        code: v?.question_type_id,
        label: _.startCase(v?.question_type_name),
    })) || [],
    preScreeningQuestions: state => state.preScreeningQuestions || [],
    preScreeningResponses: state => state.preScreeningResponses || [],
    isFetchingTransactions: state => state.isFetchingTransactions,
    preScreeningTransactions: state => state.preScreeningTransactions || [],
}

const mutations = {
    ["SET_QUESTION_TYPES"](state, payload) {
        state.questionTypes = payload;
    },
    ["SET_PRE_SCRENNING_QUESTIONS"](state, payload) {
        state.preScreeningQuestions = payload;
    },
    ["RESET_PRE_SCREENING_QUESTIONS"](state) {
        state.preScreeningQuestions = [];
    },
    ["SET_PRE_SCREENING_RESPONSES"](state, payload) {
        state.preScreeningResponses = payload;
    },
    ["RESET_PRE_SCREENING_RESPONSES"](state) {
        state.preScreeningResponses = [];
    },
    ["SET_IS_FETCHING_TRANSACTIONS"](state, payload) {
        state.isFetchingTransactions = payload;
    },
    ["UPDATE_CANDIDATE_TRANSACTIONS"](state, payload) {
        if (payload && payload.length && state.preScreeningResponses.length) {
            const index = state.preScreeningResponses.findIndex(val => val.candidate_uid === payload[0].candidate_uid);
            if (index > -1) {
                let responses = state.preScreeningResponses;
                responses[index].transactions = payload;
                state.preScreeningResponses = [...responses]
            }
        }
    },
    ["SET_PRE_SCREENING_TRANSACTIONS"](state, payload) {
        state.preScreeningTransactions = payload;
    },
    ["RESET_PRE_SCREENING_TRANSACTIONS"](state) {
        state.preScreeningTransactions = [];
    },
}

const actions = {
    initFetchingPreScreening: ({ commit, getters, dispatch }) => {
        const { questionTypes } = getters;
        let appendAction = [];
        commit("SET_FETCHING", true);
        if (!questionTypes.length)
            appendAction = [...appendAction, dispatch("fetchQuestionTypes")];
        return Promise.all(appendAction).then(res => {
            commit("SET_FETCHING", false);
            return res;
        });
    },
    fetchQuestionTypes({ commit, getters, dispatch }, all_rows = false) {
        let queryPayload = { all_rows: true };
        if(!all_rows)
            queryPayload = {...queryPayload, question_type_id__notin:[8,9]}
        const queryString = getFilterQueryStringWithoutArray(queryPayload)
        return preScreening
            .fetchQuestionTypes(queryString)
            .then(res => {
                const { data } = res;
                commit("SET_QUESTION_TYPES", data);
                return data;
            })
            .catch(err => {
                console.log("Error While Fetch Question Types", err);
                return err;
            });
    },
    createJobQuestion({ commit, getters, dispatch }, data) {
        const { getCustomerId: customer_uid } = getters;
        const { job_id, payload } = data;
        return preScreening
            .createJobQuestion({ customer_uid, payload, job_id })
            .then(res => {
                const { data } = res;
                return res;
            })
            .catch(err => {
                const message = "Error while create Job Question";
                console.log(message, err);
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message,
                });
                return err;
            })
    },
    updateJobQuestion({ commit, getters, dispatch }, data) {
        const { getCustomerId: customer_uid } = getters;
        const { job_id, payload } = data;
        return preScreening
            .updateJobQuestion({ customer_uid, payload, job_id })
            .then(res => {
                const { data } = res;
                return res;
            })
            .catch(err => {
                const message = "Error while update Job Question";
                console.log(message, err);
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message,
                });
                return err;
            })
    },
    deleteJobQuestion({ commit, getters, dispatch }, question_id) {
        const { getCustomerId: customer_uid } = getters;
        return preScreening
            .deleteJobQuestion({ customer_uid, question_id })
            .then(res => {
                const { data } = res;
                return res;
            })
            .catch(err => {
                const message = "Error while Delete Job Question";
                console.log(message, err);
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message,
                });
                return err;
            })
    },
    getPreScreeningQuestions({ commit, dispatch, getters }, job_id) {
        const { getCustomerId: customer_uid } = getters;
        const queryPayload = { job_id, all_rows: true };
        const query = getFilterQueryStringWithoutArray(queryPayload);
        return preScreening
            .getPreScreeningQuestions({ customer_uid, query })
            .then(res => {
                const { data } = res;
                commit("SET_PRE_SCRENNING_QUESTIONS", data);
                return res;
            })
            .catch(err => {
                const message = "Error while Get Job Questions";
                console.log(message, err);
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message,
                });
                return err;
            })
    },
    createCandidateTransaction({ commit, getters, dispatch }, data) {
        const { getCustomerId: customer_uid } = getters;
        const { job_id, payload, candidate_uid } = data;
        dispatch("showLoader");
        return preScreening
            .createCandidateTransaction({ customer_uid, payload, job_id, candidate_uid })
            .then(res => {
                const { data } = res;
                return res;
            })
            .catch(err => {
                const message = "Error while create Question Transaction";
                console.log(message, err);
                dispatch("showToast", {
                    class: "bg-danger text-white",
                    message,
                });
                return err;
            })
            .finally(() => {
                dispatch("hideLoader");
            })
    },
    deleteCandidateTransaction({ commit, dispatch, getters }, payload) {
        const { getCustomerId: customer_uid } = getters;
        const { job_id, candidate_uid } = payload
        return preScreening
            .deleteCandidateTransaction({ customer_uid, job_id, candidate_uid })
            .then(res => {
                const { data } = res;
                return res;
            })
            .catch(err => {
                const message = "Error while Delete Pre Screening Answers";
                console.log(message, err);
                dispatch("showToast", {
                    class: "dg-danger text-white",
                    message
                })
                return err;
            })
    },
    fetchJobPreScreeningResponses({ commit, dispatch, getters }, job_id) {
        commit("RESET_PRE_SCREENING_RESPONSES");
        commit("SET_FETCHING", true);
        const { getCustomerId: customer_uid } = getters;
        return preScreening
            .fetchJobPreScreeningResponses({ job_id, customer_uid })
            .then(res => {
                const { data } = res;
                commit("SET_PRE_SCREENING_RESPONSES", data);
                return res;
            })
            .catch(err => {
                const message = "Error While Fetch Pre Screening Responses";
                console.log(message, err);
                dispatch("showToast", {
                    class: "dg-danger text-white",
                    message
                })
                return err;
            }).finally(() => {
                commit("SET_FETCHING", false);
            })
    },
    fetchJobCandidateTransactions({ commit, dispatch, getters }, payload = {}) {
        commit("SET_IS_FETCHING_TRANSACTIONS", true);
        commit("RESET_PRE_SCREENING_TRANSACTIONS");
        const { job_id, candidate_uid } = payload;
        const { getCustomerId: customer_uid } = getters;
        let queryPayload = {}
        if (job_id)
            queryPayload = { ...queryPayload, job_id };
        if (candidate_uid)
            queryPayload = { ...queryPayload, candidate_uid };
        const queryString = getFilterQueryStringWithoutArray(queryPayload);
        return preScreening
            .fetchCandidateTransaction({ customer_uid, queryString })
            .then(res => {
                const { data } = res;
                commit("UPDATE_CANDIDATE_TRANSACTIONS", data);
                commit("SET_PRE_SCREENING_TRANSACTIONS", data);
                return res;
            })
            .catch(err => {
                const message = "Error While Fetch Candidate Response";
                console.log(message, err);
                dispatch("showToast", {
                    class: "dg-danger text-white",
                    message
                })
                return err;
            })
            .finally(() => {
                commit("SET_IS_FETCHING_TRANSACTIONS", false);
            })
    }
}

export default { state, getters, mutations, actions };